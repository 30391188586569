import * as React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Container, Row ,Col} from "react-bootstrap";
import './NewsLanding.scss';
import NewsHeader from "../../components/NewsHeader/NewsHeader";
import NewsContent from "../../components/NewsContent/NewsContent";
const NewsLanding = () =>{
    return(
        <>
        <div className="news-landing-wrap">
             <Header />
            <NewsHeader />
            <NewsContent />
            <Footer />
        </div>
        </>
    )
}
export default NewsLanding