import React, { useRef, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AeroDesk from "../../images/landing/aero.jpg";
import Car from "../../images/landing/car1800.jpg";
import CarTab from "../../images/landing/car1800.jpg";
import CarDesk from "../../images/landing/car1800.jpg";
import propertyTab02 from "../../images/landing/1802.jpg";
import DownTownDesk from "../../images/landing/1803.png";
import Location01 from "../../images/landing/place01.png";
import Location02 from "../../images/landing/place02.png";
import Location03 from "../../images/landing/place03.png";
import Location04 from "../../images/landing/place04.png";
import Location05 from "../../images/landing/place05.jpg";
import { useWindowSize } from "../../hooks/window-size";

import NewsCard from "../News/NewsCard";

import "./NewsContent.scss";
const NewsContent = () => {
  const [windowWidth] = useWindowSize();

  const [filterClose, setFilterclose] = useState(false);
  const filterBtn = () => {
    setFilterclose(!filterClose);
  };

  const NewsList = [
    {
      propertyImg: AeroDesk,
      propertyTab: AeroDesk,
      propertyDesk: AeroDesk,
      propertyTitle:
        "Aldar Properties set to be launched the ‘Dahlias’ at Yas Acres",
      Text: "6 min read",
      smText: "selling",
      content:
        "Aldar Properties (‘Aldar’) has announced the launch of Dahlias, the final community of its flagship development on Yas Island based on the high demand for every phase of Yas Acres. The community features 120 new spacious villas, duplexes, and townhouses available to be purchased by all nationalities from 13 November 2021.",
      link: "continue reading",
      category: ["all", "news","selling","mccone"],
    },
    {
      propertyImg: CarDesk,
      propertyTab: CarDesk,
      propertyDesk: CarDesk,
      propertyTitle: "Land plot sold for Dh 289 million at DIFC, Dubai",
      Text: "4 min read",
      smText: "selling",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam bibendum euismod erat, eu ornare eros sollicitudin quis. Cras sit amet justo condimentum dolor tempor...",
      link: "continue reading",
      category: ["all", "video"],
    },
    {
      propertyImg: propertyTab02,
      propertyTab: propertyTab02,
      propertyDesk: propertyTab02,
      propertyTitle:
        "Holiday homes rates are up in Dubai for a busy December and New Year’s Eve",
      Text: "7 min read",
      smText: "market",
      content:
        "Etiam cursus augue ac maximus maximus. Phasellus lobortis ante at erat fringilla, viverra pulvinar augue dictum. Quisque molestie lacinia lectus, et varius ex elementum...",
      link: "continue reading",
      category: ["all", "buying"],
    },
    {
      propertyImg: Car,
      propertyTab: CarTab,
      propertyDesk: DownTownDesk,
      propertyTitle: "Aldar Properties launched the ‘Dahlias’ at Yas Acres",
      Text: "4 min read",
      smText: "buying",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam bibendum euismod erat, eu ornare eros sollicitudin quis. Cras sit amet justo condimentum dolor tempor...",
      link: "continue reading",
      category: ["all", "selling"],
    },
    {
      propertyImg: Car,
      propertyTab: CarTab,
      propertyDesk: CarDesk,
      propertyTitle:
        "Holiday homes rates are up in Dubai for a busy December and New Year’s Eve",
      Text: "7 min read",
      smText: "buying",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam bibendum euismod erat, eu ornare eros sollicitudin quis. Cras sit amet justo condimentum dolor tempor...",
      link: "continue reading",
      category: ["all", "selling"],
    },
    {
      propertyImg: Location01,
      propertyTab: Location01,
      propertyDesk: Location01,
      propertyTitle: "Downtown Dubai",
      link: "view guide",
      category: ["all", "off plan"],
    },

    {
      propertyImg: Location02,
      propertyTab: Location02,
      propertyDesk: Location02,
      propertyTitle: "Dubai Hills Estate",
      link: "view guide",
      category: ["all"],
    },

    {
      propertyImg: Location03,
      propertyTab: Location03,
      propertyDesk: Location03,
      propertyTitle: "Arabian Ranches",
      link: "view guide",
      category: ["all", "community"],
    },
    {
      propertyImg: Location04,
      propertyTab: Location04,
      propertyDesk: Location04,
      propertyTitle: "Arabian Ranches III",
      link: "view guide",
      category: ["all", "market"],
    },
    {
      propertyImg: Location05,
      propertyTab: Location05,
      propertyDesk: Location05,
      propertyTitle: "Dubai Creek Harbour",
      link: "view guide",
      category: ["all", "mccone"],
    },
  ];

  const [filter, setFilter] = useState("all");
  let [projects, setProjects] = useState([]);
  useEffect(() => {
    setProjects(NewsList);
  }, []);
  useEffect(() => {
    setProjects([]);

    //console.log(filter);
    const filtered = NewsList.map((p) => ({
      ...p,
      filtered: p.category.includes(filter),
    }));
    setProjects(filtered);
  }, [filter]);

  return (
    <>
      <section className="category-wrap">
        <Container>
          <Row className="RowMerge">
            <Col className="RowMerge">
              <button
                type="button"
                className="category-btn dropdown-toggle btn btn-primary"
                onClick={filterBtn}
              >
                {filter == "all" ? "all categories" : filter}

                <i className="drop-arrow"></i>
              </button>
              <nav className={`nav-wrap ${filterClose ? "nav-menu-open" : ""}`}>
                <ul className="nav-menu">
                  <li>
                    <button
                      type="button"
                      className={
                        filter === "all" ? "filter-link active" : "filter-link"
                      }
                      active={filter === "all"}
                      onClick={() => {
                        setFilter("all");
                        setFilterclose(false);
                      }}
                    >
                      all news &amp; video
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className={
                        filter === "video"
                          ? "filter-link active"
                          : "filter-link"
                      }
                      active={filter === "video"}
                      onClick={() => {
                        setFilter("video");
                        setFilterclose(false);
                      }}
                    >
                      video
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className={
                        filter === "buying"
                          ? "filter-link active"
                          : "filter-link"
                      }
                      active={filter === "buying"}
                      onClick={() => {
                        setFilter("buying");
                        setFilterclose(false);
                      }}
                    >
                      buying
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className={
                        filter === "selling"
                          ? "filter-link active"
                          : "filter-link"
                      }
                      active={filter === "selling"}
                      onClick={() => {
                        setFilter("selling");
                        setFilterclose(false);
                      }}
                    >
                      selling
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className={
                        filter === "off plan"
                          ? "filter-link  active"
                          : "filter-link"
                      }
                      active={filter === "off plan"}
                      onClick={() => {
                        setFilter("off plan");
                        setFilterclose(false);
                      }}
                    >
                      off plan
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className={
                        filter === "community"
                          ? "filter-link  active"
                          : "filter-link"
                      }
                      active={filter === "community"}
                      onClick={() => {
                        setFilter("community");
                        setFilterclose(false);
                      }}
                    >
                      community
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className={
                        filter === "market"
                          ? "filter-link  active"
                          : "filter-link"
                      }
                      active={filter === "market"}
                      onClick={() => {
                        setFilter("market");
                        setFilterclose(false);
                      }}
                    >
                      market
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className={
                        filter === "mccone"
                          ? "filter-link  active"
                          : "filter-link"
                      }
                      active={filter === "mccone"}
                      onClick={() => {
                        setFilter("mccone");
                        setFilterclose(false);
                      }}
                    >
                      mccone
                    </button>
                  </li>
                </ul>
              </nav>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="news-reads">
        <div className="news-reads-wrap">
          <Container>
            <Row className="m-0 ">
              {projects.slice(0, 1).map((property) =>
                property.filtered === true ? (
                  <Col className="p-0 col-12 top-card">
                    <NewsCard key={property.id} newsCard={property} />
                  </Col>
                ) : (
                  ""
                )
              )}

              {windowWidth < 767 ? (
                <>
                  {projects.slice(1, 3).map((property) =>
                    property.filtered === true ? (
                      <Col className="p-0 col-12 col-md-6 col-lg-4  medium-card ">
                        <NewsCard key={property.id} newsCard={property} />
                      </Col>
                    ) : (
                      ""
                    )
                  )}
                </>
              ) : null}

              {windowWidth >= 768 && windowWidth <= 991 ? (
                <>
                  {projects.slice(1, 5).map((property) =>
                    property.filtered === true ? (
                      <Col className="p-0 col-6 col-md-6 col-lg-4 name789  medium-card ">
                        <NewsCard key={property.id} newsCard={property} />
                      </Col>
                    ) : (
                      ""
                    )
                  )}
                </>
              ) : null}

              {windowWidth >= 992 ? (
                <>
                  {projects.slice(1, 4).map((property) =>
                    property.filtered === true ? (
                      <Col className="p-0 col-6  col-lg-4   medium-card ">
                        <NewsCard key={property.id} newsCard={property} />
                      </Col>
                    ) : (
                      ""
                    )
                  )}
                </>
              ) : null}
            </Row>

            <Row className="m-0 row-cols-5 last-wrap">
              {windowWidth <= 1699 ? (
                <>
                  {projects.slice(5, 9).map((property) =>
                    property.filtered === true ? (
                      <Col className="p-0 col-6 col-md-3 02 small-card">
                        <NewsCard key={property.id} newsCard={property} />
                      </Col>
                    ) : (
                      ""
                    )
                  )}
                </>
              ) : null}
              {windowWidth > 1700 ? (
                <>
                  {projects.slice(5).map((property) =>
                    property.filtered === true ? (
                      <Col className=" p-0 col-6 col-md-3 03 small-card">
                        <NewsCard key={property.id} newsCard={property} />
                      </Col>
                    ) : (
                      ""
                    )
                  )}
                </>
              ) : null}
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};
export default NewsContent;
