import * as React from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import './NewsHeader.scss';
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
const NewsHeader = () =>{
    return(
        <>
        <section className="news-intro">
            <Container>
                <Row>
                    <Col>
                        <div className="newshead-box">
                        <BreadCrumbs />
                            <h1>News and Video</h1>
                            <p>Etiam cursus augue ac maximus maximus. Phasellus lobortis ante at erat fringilla, viverra pulvinar augue dictum quisque molestie lacinia lectus.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        </>
    )
}   
export default NewsHeader