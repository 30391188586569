import React from "react";
import { Link } from "gatsby";
import "./NewsCard.scss";
import GetGGFXImage from "../common/site/get-ggfx-image";
import GetGatsbyImage from "../common/site/get-gatsby-image";
import GenerateLink from "../common/site/GetLink";

const NewsCard = (props) => {
  const NewsItem = props.newsCard;

  if (NewsItem == null) {
    return null
  }

  let processedImages = JSON.stringify({});
  if (NewsItem?.imagetransforms?.Tile_Image_Transforms) {
   processedImages = NewsItem.imagetransforms.Tile_Image_Transforms;
  }

  return (
    <React.Fragment>
      <div className="news-card zoom">
        <div className="properties-wrapper">
          <Link to={`/about/latest-property-news-and-video/${NewsItem.URL}/`} className="d-inline-block zoom">
          <GetGGFXImage imagename={"areaguides.Tile_Image.tileimg"} imagesources={NewsItem.Tile_Image} fallbackalt={NewsItem.Title} imagetransformresult={processedImages} id={NewsItem.id}/>                  
          </Link>
          {NewsItem.Select_Category ? (
            <span className="sm-text">{NewsItem.Select_Category.Name}</span>
          ) : null}
        </div>
        <div className="about-properties">
          <div className="properties-info">
            <div className="properties-heading">
              <Link to={`/about/latest-property-news-and-video/${NewsItem.URL}/`}>
                <h3>
                  {NewsItem.Title}
                </h3>
              </Link>
              

              {/* <GenerateLink link={""} class="btn-newscard">View Guide<i className="icon-right"></i></GenerateLink> */}

              {NewsItem.Reading_Time ? <div className="time">
             
             <i className="icon-clock"></i>
             <span className="sm-text">{NewsItem.Reading_Time}</span>
           </div> : null }

            </div>
          </div>
          {/* <div className="content-link">
            {NewsItem.content ? <p>{NewsItem.content}</p> : null}
            {NewsItem.link ? (
              <>
                <div className="description-link">
                  <span className="read-more">
                    {NewsItem.link}
                    <i className="right-more"></i>
                  </span>
                </div>
              </>
            ) : null}
          </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewsCard;
