import * as React from "react";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Container, Row, Col } from "react-bootstrap";
import './BreadCrumbs.scss';
const BreadCrumbs = () =>{
    return(
        <Breadcrumb className='breadcrumb-wrapper'>
            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="#">About</Breadcrumb.Item>
            <Breadcrumb.Item href="#">News and Video</Breadcrumb.Item>
        </Breadcrumb>
    )
}    
export default BreadCrumbs